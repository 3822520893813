import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Nav = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  a {
    text-decoration: none;
  }
`
const Item = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  ${({ active }) => (active ? 'border-bottom: 1px solid #3AAD61;' : 'border-bottom: 1px solid #d3d3d3;')}
  ${({ active }) => (active ? 'color: #000;' : 'color: #828282;')}
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    background: #f4f4f4;
  }
`

export const useGetUser = (): User | null => {
  const [user, setUser] = useState<User | null>(null)
  useEffect(() => {
    fetch('http://inflovio.com:9000/api/me', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${window.localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log({ json })
        setUser(json)
      })
  }, [])

  return user
}

export type User = { name: string; surname: string; isAdmin: boolean }

export const Header = ({ user }: { user: User }) => {
  const location = useLocation()
  const isMyTasks = location.pathname.includes('my-tasks')
  const isNewTask = location.pathname.includes('new-task')
  const isAllTasks = location.pathname === '/'

  const logout = () => {
    window.localStorage.removeItem('token')
    window.location.href = '/'
  }
  return (
    <Nav>
      {user.isAdmin ? (
        <NavLink to="/">
          <Item active={isAllTasks}>Visi uzdevumi</Item>
        </NavLink>
      ) : (
        ''
      )}
      <NavLink to="/new-task">
        <Item active={isNewTask}>Jauns uzdevums</Item>
      </NavLink>
      <NavLink to="/my-tasks">
        <Item active={isMyTasks}>Mani uzdevumi</Item>
      </NavLink>

      <Item style={{ maxWidth: 100 }} onClick={logout}>
        Iziet
      </Item>
    </Nav>
  )
}
