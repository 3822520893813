import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Task } from '../components/Task'

const Root = styled.div`
  width: calc(100% - 40px);
  margin: 20px;
  font-size: 12px;
`

export const MyTasks = () => {
  const [tasks, setItems] = useState([])

  useEffect(() => {
    fetch('http://inflovio.com:9000/api/my', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${window.localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((json) => {
        setItems(json)
      })
  }, [])

  return (
    <>
      <Root>
        {tasks.map((task) => (
          <Task task={task} />
        ))}
      </Root>
    </>
  )
}
