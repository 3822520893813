import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  background-color: aliceblue;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgb(218, 226, 233);
  width: 300px;
`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: #939393;
  }

  input {
    border: 1px solid rgb(218, 226, 233);
    padding: 8px;
    border-radius: 4px;
  }
`

const Error = styled.div`
  color: rgb(187, 0, 0);
  margin: 10px 0;
  font-size: 13px;
`

const Button = styled.button`
  background: rgb(46, 64, 124);
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 4px;
`
export const Register = ({ onLoggedIn }: { onLoggedIn: () => void }) => {
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const [error, setError] = useState('')

  useEffect(() => {
    if (error) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, name, surname, password, repeatPassword])

  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!username || !name || !surname || !password || !repeatPassword) {
      setError('Visi lauki ir obligāti jāaizpilda')
      return
    }

    if (password !== repeatPassword) {
      setError('Paroles nesakrīt')
    }
    fetch('http://inflovio.com:9000/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password, repeatPassword, name, surname }),
    })
      .then(function (res) {
        res.json().then(({ token }) => {
          if (token) {
            localStorage.setItem('token', token)
            onLoggedIn()
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  return (
    <Root>
      <Form id="form" onSubmit={handleLogin}>
        <InputWrap>
          <label>Lietotājvārds</label>
          <input name="login" id="login" value={username} onChange={(e) => setUsername(e.target.value)} />
        </InputWrap>
        <InputWrap>
          <label>Vārds</label>
          <input name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrap>
        <InputWrap>
          <label>Uzvārds</label>
          <input name="surname" id="surname" value={surname} onChange={(e) => setSurname(e.target.value)} />
        </InputWrap>
        <InputWrap>
          <label>Parole</label>
          <input name="password" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </InputWrap>
        <InputWrap>
          <label>Parole atkārtoti</label>
          <input name="password" id="password" type="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
        </InputWrap>
        {error ? <Error>{error}</Error> : null}
        <div>
          <Button type="submit">Reģistrēties</Button>
        </div>
      </Form>
    </Root>
  )
}
