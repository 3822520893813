import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Header } from './Header'

const Root = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  max-width: 550px;
  align-self: center;
  font-size: 12px;
  > div:first-of-type {
    // align-self: flex-start;
  }
`

const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  width: 100%;
`
const Item = styled.div`
  padding: 8px 0;
  width: 100%;
  border-top: 1px solid #ccc;
  align-items: flex-start;
  background: #fafafa;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  margin: 32px 0;
  position: relative;
`

const FirstRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 6px));
  gap: 8px;
  justify-content: center;
  margin-bottom: 8px;
`

const AddButton = styled.button`
  width: 100%;
  display: block;
  padding: 8px;
  background: #6b6b6b;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const SaveButton = styled.button`
  width: 100%;
  display: block;

  padding: 8px;
  background: #7a9984;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const DeleteButton = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 36px;
  height: 36px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 36px;
  border: none;
  background: #f0b9b9;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const Label = styled.div`
  padding-left: 8px;
`
const SmallLabel = styled.div``

const empty = { code: '', nr: '', description: '', minutes: '', hours: '' }

export const Form = () => {
  const [items, setItems] = useState([{ ...empty }])
  const [date, setDate] = useState('')

  const history = useHistory()
  const addItem = () => {
    setItems([...items, { ...empty }])
  }

  const handleChange = (field: keyof typeof empty, val: string, index: number) => {
    const newItems = [...items]

    newItems[index][field] = val

    setItems(newItems)
  }
  const removeItem = (index: number) => {
    if (items.length === 1) {
      return setItems([{ ...empty }])
    }

    const newItems = [...items.slice(0, index), ...items.slice(index + 1)]
    setItems(newItems)
  }

  const saveItem = () => {
    fetch('http://inflovio.com:9000/api/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${window.localStorage.getItem('token')}` },
      body: JSON.stringify({ date, project: items }),
    }).then(() => {
      history.push('/my-tasks')
    })
  }
  return (
    <>
      <Root>
        <div>
          <Label>Datums:</Label>
          <StyledInput type="date" placeholder="Datums" value={date} onChange={(e) => setDate(e.target.value)} />
        </div>

        <div>
          {items.map((item, i) => (
            <Item key={i}>
              <FirstRow>
                <div>
                  <SmallLabel>Projekta Nr:</SmallLabel>
                  <StyledInput value={item.nr} onChange={(e) => handleChange('nr', e.target.value, i)} />
                </div>
                <div>
                  <SmallLabel>Process</SmallLabel>
                  <StyledInput value={item.code} onChange={(e) => handleChange('code', e.target.value, i)} />
                </div>
                <div>
                  <SmallLabel>Stundas</SmallLabel>
                  <StyledInput value={item.hours} onChange={(e) => handleChange('hours', e.target.value, i)} />
                </div>
                <div>
                  <SmallLabel>Minūtes</SmallLabel>
                  <StyledInput value={item.minutes} onChange={(e) => handleChange('minutes', e.target.value, i)} />
                </div>
              </FirstRow>
              <div>
                <SmallLabel>Darba Apraksts:</SmallLabel>
                <StyledInput value={item.description} onChange={(e) => handleChange('description', e.target.value, i)} />
              </div>

              <DeleteButton onClick={() => removeItem(i)}>X</DeleteButton>
            </Item>
          ))}
        </div>
        <AddButton onClick={addItem}>Pievienot vēl darbus</AddButton>
        <SaveButton onClick={saveItem}>Saglabāt</SaveButton>
      </Root>
    </>
  )
}
