import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  padding: 4px;
  max-width: 100%;
  display: grid;
  grid-template-columns: 100px 100px auto;
  background: #fafafa;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;

  @media screen and (max-width: 771px) {
    grid-template-rows: auto;
    grid-template-columns: auto;
    gap: 10;
  }
`
const Project = styled.div`
  @media screen and (max-width: 771px) {
    margin-top: 20px;
  }
`
const ProjectItem = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin: 6px;

  &:first-of-type {
    margin-top: 0;
  }

  span {
    display: block;

    &:first-of-type {
      padding: 4px 0;
      color: #8e8e8e;
      font-size: 10px;
    }
  }
`

type Props = {
  task: Task
}
export const Task = ({ task }: Props) => {
  return (
    <Root>
      <div>
        {task.name} {task.surname}{' '}
      </div>
      <div>{task.date}</div>
      <Project>
        {task.project.map((proj, i) => {
          return (
            <ProjectItem key={i}>
              <div>
                <span>Projekta nr:</span>
                <span>{proj.nr}</span>
              </div>
              <div>
                <span>Process:</span>
                <span>{proj.code}</span>
              </div>
              <div>
                <span>Apraksts</span>
                <span>{proj.description}</span>
              </div>
              <div>
                <span>Laiks:</span>
                <span>
                  {proj.hours || 0}h {proj.minutes || 0}min
                </span>
              </div>
              {proj.hasOwnProperty('sum') ? (
                <div>
                  <span>Summa:</span>
                  <span>{proj.sum} eur</span>
                </div>
              ) : null}
            </ProjectItem>
          )
        })}
      </Project>
    </Root>
  )
}
