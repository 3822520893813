import React, { FormEvent, FormEventHandler, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  background-color: aliceblue;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgb(218, 226, 233);
  width: 300px;

  a {
    display: block;
    padding: 10px 0 0;
    font-size: 12px;
  }
`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: #939393;
  }

  input {
    border: 1px solid rgb(218, 226, 233);
    padding: 8px;
    border-radius: 4px;
  }
`

const Button = styled.button`
  background: rgb(46, 64, 124);
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 4px;
`
export const Login = ({ onLoggedIn }: { onLoggedIn: () => void }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    fetch('http://inflovio.com:9000/api/login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ username, password }) })
      .then(function (res) {
        res.json().then(({ token }) => {
          if (token) {
            localStorage.setItem('token', token)
            onLoggedIn()
          }
        })
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  return (
    <Root>
      <Form id="form" onSubmit={handleLogin}>
        <InputWrap>
          <label>Lietotājvārds</label>
          <input name="login" id="login" value={username} onChange={(e) => setUsername(e.target.value)} />
        </InputWrap>
        <InputWrap>
          <label>Parole</label>
          <input name="password" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </InputWrap>
        <div>
          <Button type="submit">Ienākt</Button>
          <NavLink to="/sign-up">Reģistrēties</NavLink>
        </div>
      </Form>
    </Root>
  )
}
