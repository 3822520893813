import React, { useCallback, useState } from 'react'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { EditTask } from './Pages/EditTask'
import { Form } from './Pages/Form'
import { Header, useGetUser } from './Pages/Header'
import { Login } from './Pages/Login'
import { MyTasks } from './Pages/MyTasks'
import { Register } from './Pages/Register'
import { ShowAll } from './Pages/ShowAll'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token'))

  const user = useGetUser()

  const onLogIn = useCallback(() => {
    const token = localStorage.getItem('token')

    if (token && !isLoggedIn) {
      setIsLoggedIn(token)
    }
  }, [isLoggedIn])

  if (!user) {
    return null
  }

  if (!isLoggedIn) {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Login onLoggedIn={onLogIn} />} />
          <Route path="/sign-up" exact component={() => <Register onLoggedIn={onLogIn} />} />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    )
  }
  return (
    <Router>
      <Header user={user} />
      <Switch>
        <Route path="/" exact component={() => <ShowAll isAdmin={user.isAdmin} />} />
        <Route path="/edit/:id" exact render={({ match }) => <EditTask isAdmin={user.isAdmin} id={match.params!.id} />} />
        <Route path="/new-task" exact component={Form} />
        <Route path="/my-tasks" exact component={MyTasks} />
        <Route component={() => <Redirect to="/new-task" />} />
      </Switch>
    </Router>
  )
}

export default App
