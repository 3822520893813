import React, { useEffect, useState } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Task } from '../components/Task'
import { useGetUser } from './Header'

const Table = styled.div`
  width: calc(100% - 40px);
  margin: 20px;
  font-size: 12px;

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const ShowAll = ({ isAdmin }: { isAdmin: boolean }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    fetch('http://inflovio.com:9000/api/all', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${window.localStorage.getItem('token')}` },
    })
      .then((res) => {
        if (res.status === 400) {
          throw new Error('Unauthorized')
        }
        return res.json()
      })
      .then((json) => {
        setItems(json)
      })
      .catch((err: any) => {
        //
      })
  }, [])

  if (!isAdmin) {
    return <Redirect to="/new-task" />
  }

  return (
    <div>
      <Table>
        {items.map((task: Task) => {
          return (
            <NavLink to={`edit/${task.id}`} key={task.id}>
              <Task task={task} />
            </NavLink>
          )
        })}
      </Table>
    </div>
  )
}
